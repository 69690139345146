@import "./variables";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    font-family: 'Oswald', sans-serif;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        cursor: pointer;
        background-color: $modest-color;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba($primary-color, 0.5);
        border-radius: $border-radius;
        background-color: rgba($primary-color, 0.3);
    }
}

html {
    max-height: 100vh;
    scroll-behavior: smooth;
}

main {
    margin: auto;

    h1 {
        margin: auto;
        margin-bottom: 30px;
        text-align: left;
        color: white;
        font-size: 25px;
        letter-spacing: 0.1em;
    }

    // section {
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-attachment: fixed;
    //     background-position: center;

    //     @media screen and (max-width: 1000px) {
    //         background-attachment: scroll !important;
    //     }
    // }
}

.blur {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(gray, 0.05);
    backdrop-filter: blur(10px);
}