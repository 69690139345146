.classroomItem-container {
    margin: 20px;
    box-shadow: 0px 4px 16px rgba(158, 158, 158, 0.25);
    background-color: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;

    .photo-container {
        padding: 20px;

        .classroom-image {
            width: 100%;
            height: 100%;
            border-radius: 16px;
        }
    }

    .info-container {
        padding: 20px;
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;

        >* {
            margin-bottom: 10px;
        }

        .capacity {
            font-size: 1.4rem;
            font-style: italic;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal;
            text-overflow: ellipsis;
        }

        .description {
            color: #ADADAD;
            overflow: hidden;
            word-wrap: normal;
            text-overflow: ellipsis;
        }

        @media screen and (max-width: 800px) {
            padding: 0px 20px;
        }
    }

    .button {
        min-width: 50%;
        margin: 10px auto;
    }
}