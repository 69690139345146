.enter {
    opacity: 0;
    transition: all 600ms cubic-bezier(0.15, 0.84, 0.21, 0.96);
}

.enterActive {
    opacity: 1;
}

.exit {
    opacity: 1;
    transition: all 600ms cubic-bezier(0.15, 0.84, 0.21, 0.96);
}

.exitActive {
    opacity: 0;
}