@import "src/styles/variables";

.itemModal-container {
    min-width: 50vw;
    max-height: 85vh;

    .info-container {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media screen and (max-width: 1000px) {
            grid-template-columns: 1fr;
        }

        .modal-photo-container {
            img {
                width: 100%;
            }
        }

        .description-container {
            padding: 10px 40px;

            @media screen and (max-width: 1000px) {
                padding: 0;
                margin-top: 10px;
            }

            >* {
                margin-bottom: 15px;
            }

            h3 {
                font-style: italic;
            }

            span {
                display: block;
                margin-left: 10px;
                margin-top: 10px;
                color: gray;

                @media screen and (max-width: 1000px) {
                    margin-left: 0;
                }
            }

            li {
                span {
                    display: inline-block;
                    word-wrap: break-word;
                }
            }
        }
    }
}