@import "../variables";


.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999;

    .notification {
        background: #fff;
        transition: .3s ease;
        position: relative;
        pointer-events: auto;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 20px 30px;
        margin-bottom: 15px;
        max-height: 1000px;
        border-radius: 3px;
        box-shadow: 0 0 10px #999;
        color: #fff;
        opacity: .9;
        background-position: 15px;
        background-repeat: no-repeat;

        display: flex;
        justify-content: center;
        align-items: center;

        &.success{
            background-color: $success-color;
        }

        &.danger{
            background-color: $danger-color;
        }

        &:hover {
            box-shadow: 0 0 12px #fff;
            opacity: 1;
            cursor: pointer
        }

        .notification-title {
            font-weight: 700;
            font-size: 20px;
            text-align: left;
            margin-top: 0;
            margin-bottom: 6px;
            width: 300px;
            height: 18px;
        }

        .notification-message {
            margin: 0;
            font-size: 16px;
            text-align: left;
            height: 18px;
            margin-left: -1px;
        }

        .notification-image {
            float: left;
            margin-right: 15px;
        }
    }
}

.top-right {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.top-left {
    top: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
}

.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);

    }

    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);

    }

    to {
        transform: translateX(0);
    }
}