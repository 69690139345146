@import "../variables";

.selectContainer {
    position: relative;
    min-width: 200px;

    select {
        width: 100%;
        height: 45px;
        -webkit-appearance: none;
        padding: 10px 41px 10px 10px;
        border: 1px solid $modest-color;
        border-radius: 5px;
        background-color: $modest-color;
        box-shadow: 0 1px 3px -2px #9098A9;
        cursor: pointer;
        font-family: inherit;
        font-size: 16px;
        transition: all 150ms ease;

        &:focus {
            outline: none;
            border-color: $primary-color;
            border-width: 2px;
            box-shadow: 0 0 0 2px rgba($primary-color, .4);
        }
    }

    span {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        top: 10px;
        right: 8px;
        background-size: cover;
        transition: all 0.3s ease;

        &.active {
            transform: rotate(180deg);
        }
    }
}