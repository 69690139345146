.mainPage-container {
    section {
        padding-top: 30px;
        padding-bottom: 30px;

        h2 {
            color: white;
            text-align: center;
            font-size: 2.5rem;
            margin-bottom: 30px;
        }
    }

    .images-container {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-position: center;

        .carouselItem-container {
            width: 60%;
            height: 600px;
            max-height: 60vh;

            position: relative;
            margin: 2rem 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .image-container {
                width: 100%;
                height: 100%;

                border-radius: 16px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

                .image {
                    width: 100%;
                    height: 100%;
                }
            }

            .name,
            .place {
                position: absolute;
                color: black;
                font-size: 2rem;
                background-color: rgba(white, 0.6);
                padding: 10px;
                border-radius: 8px;
                font-style: italic;
            }

            .name {
                bottom: 10px;
                left: 10px;
            }

            .place {
                right: 10px;
                top: 10px;
            }

            @media screen and (max-width: 1000px) {
                width: 80%;

                .name,
                .place {
                    font-size: 1rem;
                    padding: 5px;
                }
            }
        }
    }
}