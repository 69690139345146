@import "src/styles/variables";

.confirm-message {
    letter-spacing: 0;
    font-size: 20px;
    color: black;
    background: rgba(7, 59, 114, 0.1);
    border-radius: $border-radius;
    padding: 20px;

    a {
        color: $primary-color;
    }

    span {
        color: red;
    }
}

.booking-form {
    >h3 {
        padding: 20px;
        background-color: rgba($danger-color, 0.3);
        border-radius: 16px;
        color: $primary-color;
        line-height: 130%;

        span {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    form {
        width: 90%;
        margin: auto;
        padding: 20px;
        background: rgba(7, 59, 114, 0.1);
        border-radius: $border-radius;

        @media screen and (max-width: 1000px) {
            grid-template-columns: 1fr;
            width: 100%;
        }

        h3 {
            font-family: 'Montserrat';
            margin-bottom: 30px;
        }

        >div {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .description {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            &.button-container > * {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            .close-day-picker-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 100;
            }

            .datePicker {
                position: relative;
                margin-bottom: 20px;

                .day-picker-container {
                    z-index: 999;
                    position: absolute;
                    background-color: white;
                    border-radius: 16px;

                    bottom: 90%;
                    left: 00%;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -15px;
                        border-width: 15px;
                        border-style: solid;
                        border-color: rgba($color: white, $alpha: 1) transparent transparent transparent;
                    }

                    h2 {
                        margin: 0;
                        color: black;
                        font-size: 140%;
                        text-align: left;
                        text-transform: uppercase;

                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }

                    .rdp {
                        margin-bottom: 0;
                    }

                    .datetime {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        margin-bottom: 10px;
                        padding: 0 20px;

                        >div {
                            margin-right: 10px;

                            &:last-child {
                                margin: 0;
                            }
                        }
                    }

                    .allDay-container {
                        padding: 5px 30px;

                        label {
                            cursor: pointer;

                            input {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr;

                .description {
                    grid-column-end: 2;
                }

                .datePicker {
                    grid-column-end: 2;
                }
            }

            >div {
                margin: 5px;

                label {
                    margin-bottom: 5px;
                    display: inline-block;
                }
            }
        }
    }
}