@import "src/styles/variables";

.team-container {
    background: linear-gradient(rgb(43, 139, 130), rgb(91, 165, 58));

    .team-list-container {
        width: 80%;
        margin: 50px auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: space-evenly;

        .team-list-item {

            background-color: rgba(white, 0.3);
            border-radius: $border-radius;

            margin: 0 20px;
            padding: 20px 0;
            margin-bottom: 20px;

            .image-container {
                display: flex;
                justify-content: center;
                position: relative;

                img {
                    position: relative;
                    border-radius: 50%;
                    width: 230px;
                    height: 230px;
                    transition: all 0.3s ease;

                    &:hover {
                        transform: scale(1.05);
                    }

                    @media screen and (max-width: 600px) {
                        width: 150px;
                        height: 150px;
                    }
                }

                @media screen and (max-width: 600px) {
                    height: 170px;
                }
            }

            .info-container {
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;

                >* {
                    &:first-child {
                        font-size: 22px;
                    }

                    &:last-child {
                        font-size: 18px;
                        font-style: italic;
                        color: $modest-color;
                    }
                }
            }
        }

        @media screen and (max-width: 1500px) {
            grid-template-columns: 1fr 1fr 1fr;

            >*:last-child {
                grid-column-start: 2;
            }
        }

        @media screen and (max-width: 1000px) {
            grid-template-columns: 1fr 1fr;
            width: 100%;

            >*:last-child {
                grid-column-start: auto;
            }
        }
    }
}