@import "../variables";

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 55;
        cursor: pointer;
    }

    .modal-content {
        z-index: 60;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 25px 25px;
        border-radius: $border-radius;
        background: white;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);

        @media screen and (max-width: 1000px) {
            width: 100vw;
            height: 100vh;
            border-radius: 0px;
            padding: 20px;
        }

        .modal-control{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            
            .modal-title {
                position: absolute;
                left: 30px;
                top: 20px;
                font-weight: 500;
                font-size: 20px;
                z-index: 10;
            }

            .close {
                position: absolute;
                right: 20px;
                top: 20px;
                font-size: 30px;
                z-index: 15;
                cursor: pointer;
                color: black;
            }
        }

        .modal-body {
            max-height: 90vh;
            max-width: 70vw;
            overflow-y: auto;

            @media screen and (max-width: 1000px) {
                max-width: 100vw;
                max-height: calc(100vh - 120px);
            }
        }
    }
}