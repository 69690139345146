@import "src/styles/variables";

.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 2vw;
    margin-bottom: 20px;
    z-index: 50;

    font-size: 1.2rem;

    @media screen and (max-width: 1200px) {
        font-size: 1rem;
        padding: 10px 20px;
    }

    @media screen and (max-width: 800px) {
        position: fixed;
        background-color: rgba($color: white, $alpha: 0.3);
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar-left {
        display: flex;
        align-items: center;

        .logo {
            margin-right: 20px;

            display: flex;
            align-items: center;
        }
    }

    .navbar-right {
        position: relative;
        height: 70px;
        display: flex;
        align-items: center;

        ul {
            display: flex;
            align-items: center;

            li {
                span {
                    display: inline-block;
                    font-size: inherit;
                    color: white;
                }
            }

            .navLink {
                span {
                    padding: 0 10px;
                    font-family: 'Rubik';

                    @media screen and (max-width: 800px) {
                        padding: 0;
                    }
                }
            }

            .userInfo {
                .name {
                    font-style: italic;
                    font-weight: 500;
                    padding: 10px 20px;

                    @media screen and (max-width: 1000px) {
                        padding: 10px 20px;
                    }

                    @media screen and (max-width: 800px) {
                        padding: 0;
                    }
                }
            }
        }

        @media screen and (max-width: 800px) {
            nav {
                position: absolute;
                top: -10px;
                right: -170px;
                width: 300px;
                height: 50vh;

                padding: 60px 20px;
                padding-top: 80px;

                background-color: white;
                border-radius: 8px 0 0 8px;

                transition: all 600ms ease-in-out;

                opacity: 0;
                z-index: -1;
                transform: scaleX(0);

                ul {
                    position: relative;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    height: 100%;

                    li {
                        padding: 10px;

                        span {
                            color: rgb(91, 165, 58);
                            text-transform: uppercase;
                            letter-spacing: 1.2;
                        }
                    }

                    .userInfo {
                        position: absolute;
                        top: -60px;
                        left: -10px;
                    }

                    .logOut {
                        cursor: pointer;
                        font-family: 'Rubik';
                    }
                }

                &.active {
                    opacity: 1;
                    z-index: 60;
                    right: -20px;
                    transform: scaleX(1);
                }
            }
        }

        .buttons-container {
            button {
                padding: 5px 20px;
                height: auto;
            }
        }

        .hamburger {
            display: none;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 70;

            height: 50px;
            width: 50px;
            cursor: pointer;
            border-radius: 50%;
            transition: all 0.2s ease-in-out;
            background: rgba(255, 255, 255, 0.2);

            @media screen and (max-width: 800px) {
                display: block;
            }

            &:hover {
                transform: scale(1.2);
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
            }

            .bar-1,
            .bar-2,
            .bar-3 {
                position: absolute;
                left: 25%;
                top: 50%;
                width: 25px;
                height: 3px;
                background-color: white;
                transition: all 400ms cubic-bezier(.84, .06, .52, 1.8);
            }

            .bar-1 {
                transform: translateY(8px);
                animation-delay: 100ms;
            }

            .bar-3 {
                transform: translateY(-8px);
                animation-delay: 250ms;
            }

            .clear {
                clear: both;
            }

            &.active {
                background: rgba(43, 139, 130, 0.1);

                .bar-1,
                .bar-2,
                .bar-3 {
                    background-color: rgb(91, 165, 58);
                }

                .bar-1 {
                    transform: rotate(40deg);
                }

                .bar-3 {
                    transform: rotate(-40deg);
                }

                .bar-2 {
                    opacity: 0;
                }
            }
        }
    }

    .logIn-button {
        button {
            padding: 5px 20px;
            height: auto;
        }
    }
}