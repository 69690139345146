@import "/src/styles/variables.scss";

.input-time-container {
    position: relative;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .label {
        padding: 10px 20px;
        background-color: $modest-color;
        border-radius: $border-radius;
        cursor: pointer;
    }

    .time-datalist {
        position: absolute;
        z-index: 99999;
        bottom: 100%;
        max-height: 300px;
        overflow-y: auto;
        background-color: white;
        border: 1px solid black;
        border-radius: $border-radius;

        .time-datalist-item {
            cursor: pointer;
            padding: 10px 20px;

            &:hover {
                background-color: $modest-color;
            }
        }
    }
}