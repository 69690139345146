.tooltip-container {
    position: relative;
    display: inline-block;

    .tooltip-text {
        visibility: hidden;
        min-width: 120px;
        background-color: rgba($color: black, $alpha: 0.7);
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;

        position: absolute;
        z-index: 1;

        bottom: 120%;
        left: 50%;
        margin-left: -60px;

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: rgba($color: black, $alpha: 0.7) transparent transparent transparent;
        }
    }

    &:hover>.tooltip-text {
        visibility: visible;
    }
}