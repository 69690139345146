.auth-container {
    form {
        display: inline-block;
        margin: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 7px;

        >* {
            text-align: center;
            width: 300px;
            margin: 10px auto;
        }

        img {
            width: 60px;
            height: 60px;
        }

        h1 {
            margin-bottom: 20px;
            font-size: 2em;
            letter-spacing: 0.1em;
            color: black;
        }

        a {
            margin-top: 0;
            text-align: left;
            display: inline-block;
            font-size: 14px;
            color: black;

            &:hover {
                color: #0057FF;
            }
        }
    }
}