.section-loader-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9999;

    background: linear-gradient(rgb(91, 165, 58), rgb(43, 139, 130));

    >span{
        position: relative;
        color: white;
        letter-spacing: 0.3rem;
        font-size: 30px;
    }
}