@import "src/styles/variables";

.carousel-container {
    overflow: hidden;
    position: relative;

    .inner {
        white-space: nowrap;
        transition: all 1s ease-in-out;

        .carousel-item {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }

    .indicators {
        position: absolute;
        bottom: 15px;
        width: 100%;

        display: flex;
        justify-content: center;

        .indicators-container {
            position: relative;

            display: flex;
            justify-content: center;

            border-radius: 15px;

            >* {
                cursor: pointer;
                position: relative;
                padding: 4px;
                margin: 0 2px;
                border-radius: 50px;
                transition: all 0.5s ease-in-out;
                background-color: rgba(white, 0.7);

                &.active {
                    background-color: rgba($primary-color, 1);
                    transform: scale(1.25);
                }
            }

        }
    }
}