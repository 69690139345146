.footer-container {
    background-color: #073B72;

    display: grid;
    grid-template-columns: 2fr 1fr;

    color: white;

    padding: 40px 10%;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }

    .contacts-info {

        >div {
            display: grid;
            grid-template-columns: 1fr 1fr;

            @media screen and (max-width: 800px) {
                grid-template-columns: 1fr;
            }

            ul {
                padding-right: 10px;

                li {
                    margin: 10px 0;
                }

                a {
                    color: white;
                    text-decoration: underline;
                }

            }
        }
    }

    .messangers {
        .messangers-container {
            display: flex;
            flex-wrap: wrap;

            a {
                display: inline-block;
                margin: 5px;

                &:first-child {
                    margin-left: 0;
                }

                >* {
                    border-radius: 3px;
                }
            }
        }
    }
}