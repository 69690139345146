$border-radius: 8px;

$primary-color: #006CB5;
$secondary-color:#77787B;
$danger-color: #FF6363;
$success-color: #7DC71C;

$base-color: #FFFFFF;
$modest-color: #EFF0F6;

$modest-color-font: #4C5862;
